(function($, emailjs, window, document, undefined) {

  'use strict';

  //Add background color to header on Scroll past hero banner

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if(!$('header').hasClass('scroll-bg')){
      if(scroll > '245'){
        $('header').addClass('scroll-bg');
      }
    }
    else{
      if(scroll < '245'){
        $('header').removeClass('scroll-bg');
      }
    }
  });

  //Scrolltop event
  $('.scrollbackup').on('click', function () {
    $('body, html').animate({scrollTop: 0}, 500);
  });

  $(window).scroll(function () {
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > 600) {
      $('.scrollbackup').show();
      setTimeout(function () {
        $('.scrollbackup').stop().css({opacity: 1});
      }, 100);
    } else if (scrollTop < 400) {
      $('.scrollbackup').stop().css({opacity: 0});
      setTimeout(function () {
        $('.scrollbackup').hide();
      }, 300);
    }
  });

  // close menu on navigation click
  var toggleMenu = (function($) {
    jQuery('.click-mobile').click( function(){
      jQuery('#js-closeMenu').click();
    });
  });

  var formCalltoAction = (function($) {

    var $form = $('form#ctaForm');

    var showFormError = function() {
      $form.find('.form-error')
        .html('Veuillez vérifier vos réponses, certains champs sont vides ou non valides').show();
    };

    var hideFormError = function() {
      $form.find('.form-error').html('').hide();
    };

    var isEmail = function(email) {
      var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
    };

    var isPhone = function(phone) {
      var regex = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
      return regex.test(phone);
    };

    // validate one field given its value and data type [email or phone]
    var validateField = function(v, dt) {
      var r = {
        'value': true,
        'message': ''
      };
      if (v === '') {
        r.value = false;
      }
      if (dt === 'email' && !isEmail(v)) {
        r.value = false;
      } else if (dt === 'phone' && !isPhone(v)) {
        r.value = false;
      }
      return r;
    };


    var validatePage = function($page){
      var $inputGroup = $page.find('.form-group');
      var valid = true;
      $inputGroup.each(function(i, e){
        var $e = $(e);
        // Si checkbox ou radiobutton
        if ($e.hasClass('form-checkbox-list')){
          if ($e.find('input:checked').length === 0) {
            valid = false;
          }
        } else {
          $e.find('input, select, textarea').each(function(index, element) {
            var $input = $(element);
            var validateResult = validateField($input.val(), $input.data('type'));
            if (!validateResult.value) {
              valid = false;
            }
          });
        }
      });
      return valid;
    };


    var navigateToNextPage = function(currentTarget) {
      var $currentPage = $(currentTarget).closest('.lightbox-content.page');

      // Validate current page avant
      if (validatePage($currentPage)){
        hideFormError();
        var $nextPage = $currentPage.next('.lightbox-content.page');
        $currentPage.attr('data-slide', 'hideLeft');
        $nextPage.attr('data-slide', 'show');
      } else {
        showFormError();
      }

    };

    var sendEmail = function(currentTarget) {
      navigateToNextPage(currentTarget);
      var service_id = "default_service";
      var template_id = "template_pz7QiUbS";
      var $pageConfirm = $form.find('.page-confirm');
      emailjs.sendForm(service_id, template_id, 'ctaForm')
        .then(function() {
          $pageConfirm.find('.wait').hide(function(){
            $pageConfirm.find('.message.success').show();
          });
        }, function() {
          $pageConfirm.find('.wait').hide(function(){
            $pageConfirm.find('.message.error').show();
          });
        });
    };

    var datajs = function(key, value) {
      return document.querySelectorAll('[data-' + key + '=' + value + ']');
    };

    var clickHandler = function(e) {
      e.preventDefault();
      var $currentPage = $(e.currentTarget).closest('.lightbox-content.page');
      if (validatePage($currentPage)){
        sendEmail(e.currentTarget);
      } else {
        showFormError();
      }
    };

    var resetForm = function(){
      $('body').removeClass('no-overflow');
      setTimeout(function(){
        $form.find('.page').removeAttr('data-slide');
        $form.find('.page-confirm').attr('data-slide', 'hideRight');
      }, 300);
    };

    var preventBodyScroll = function(){
      $('body').addClass('no-overflow');
    };

    var bindEvents = function() {
      $(datajs('type', 'submit')).on('click', clickHandler);
      $form.find('button.nextPage').on('click', function(e) {
        navigateToNextPage(e.currentTarget);
      });

      $form.on("keypress", ":input:not(textarea):not([type=submit])", function(event) {
        if (event.keyCode == 13) {
            event.preventDefault();
        }
      });

      $('#close-lightbox').on('click', resetForm);
    };

    var init = function() {
      bindEvents();
    };

    return {
      init: init
    };

  })(jQuery);

  $(document).ready(function () {
    //rotation speed and timer
    var speed = 5000;

    var run = setInterval(rotate, speed);
    var slides = $('.slide');
    var container = $('#slides ul');
    var elm = container.find(':first-child').prop("tagName");
    var item_width = container.width();
    var previous = 'prev'; //id of previous button
    var next = 'next'; //id of next button
    slides.width(item_width); //set the slides to the correct pixel width
    container.parent().width(item_width);
    container.width(slides.length * item_width); //set the slides container to the correct total width
    container.find(elm + ':first').before(container.find(elm + ':last'));
    resetSlides();


    //if user clicked on prev button

    $('#buttons a').click(function (e) {
      //slide the item

      if (container.is(':animated')) {
        return false;
      }
      if (e.target.id == previous) {
        container.stop().animate({
          'left': 0
        }, 1500, function () {
          container.find(elm + ':first').before(container.find(elm + ':last'));
          resetSlides();
        });
      }

      if (e.target.id == next) {
        container.stop().animate({
          'left': item_width * -2
        }, 1500, function () {
          container.find(elm + ':last').after(container.find(elm + ':first'));
          resetSlides();
        });
      }

      //cancel the link behavior
      return false;

    });

    //if mouse hover, pause the auto rotation, otherwise rotate it
    container.parent().mouseenter(function () {
      clearInterval(run);
    }).mouseleave(function () {
      run = setInterval(rotate, speed);
    });


    function resetSlides() {
      //and adjust the container so current is in the frame
      container.css({
        'left': -1 * item_width
      });
    }

  });
//a simple function to click next link
//a timer will call this function, and the rotation will begin

  function rotate() {
    $('#next').click();
  }

  formCalltoAction.init();
  toggleMenu();

})(jQuery, emailjs, window, document);
